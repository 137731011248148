import React from 'react';
import './Contacto.css'; // Ajusta el nombre del archivo CSS según sea necesario
import logo from '../images/logo.jpg'; // Ajusta la ruta de la imagen del logo según sea necesario
import { FaInstagram } from "react-icons/fa";
import logoMeLi from '../images/Logo MeLi.svg'
import { FaFacebookSquare } from "react-icons/fa";
import { FaMapPin } from "react-icons/fa";
import { FaPhone } from "react-icons/fa6";
import { MdOutlineMail } from "react-icons/md";

const Contacto = () => {
  return (
    <div className="contacto-page">
      <div className="contacto-content">
        <div className="contacto-logo">
          <img src={logo} alt="Logo" />
        </div>
        <div className="contacto-info">
          <h3>Información de Contacto</h3>
          <p>Email: repuestoselmate@gmail.com</p>
          <p>Teléfono: (02954) 429-995</p>
        </div>
        <div className="contacto-redes-sociales">
          <h3>Redes Sociales</h3>
          <ul>
            <li><a href="https://www.instagram.com/"><FaInstagram fontSize={30} color='white'/></a></li>
            <li><a href="https://www.facebook.com/"><FaFacebookSquare fontSize={30} color='white'/></a></li>
            <li><a href="https://www.mercadolibre.com/"><img src={logoMeLi} height={30}alt="MercadoLibre" className='meli-logo-contact'/></a></li>
          </ul>
        </div>
      </div>
      <div className="contacto-sucursales">
        <h3 className='contacto-sucursales-title'>Sucursales</h3>
    <div>
      <h5 style={{marginTop: 0}}><strong>Santa Rosa, LP</strong></h5>
      <p><FaMapPin color='#01703d' className='icon-contact'/>
Baldomero Tellez 26</p>
      <p><FaPhone color='#01703d'className='icon-contact'/>
2954 614 827</p>
      <p><MdOutlineMail color='#01703d'className='icon-contact'/><a href="mailto:sr@franquiciasarbo.com" style={{textDecoration: 'none', color: 'inherit'}}>
sr@franquiciasarbo.com</a></p>
    </div>
    {/* <div>
      <h5><strong>Mar del Plata, BA</strong></h5>
      <p>Tierra del Fuego 2141</p>
      <p>223 575 4900</p>
      <p><a href="mailto:mdp@franquiciasarbo.com">mdp@franquiciasarbo.com</a></p>
    </div>
    <div>
      <h5><strong>Tandil, BA</strong></h5>
      <p>11 3186 1835</p>
      <p><a href="mailto:tandil@franquiciasarbo.com">tandil@franquiciasarbo.com</a></p>
    </div>
    <div>
      <h5><strong>Gral. Pico, LP</strong></h5>
      <p>Calle 33 nº 1280</p>
      <p>2302 464 921</p>
      <p><a href="mailto:pico@franquiciasarbo.com">pico@franquiciasarbo.com</a></p>
    </div>
    <div>
      <h5><strong>San Bernardo</strong></h5>
      <p>Tucumán 2664</p>
      <p>02257 46-1749</p>
    </div> */}
      </div>
      </div>
  );
}

export default Contacto;
