import React from "react";
import logo from '../images/logo.jpg'
import './Home.css'
import { FaMapPin } from "react-icons/fa";
import { FaPhone } from "react-icons/fa6";
import { MdOutlineMail } from "react-icons/md";
const Home = () => {
  return (
 <div className="home-content">
        <div className="logo-home">
          <img src={logo} alt="Logo" />
        </div>
        <div className="contact-info">
          <h1>Repuestos El Mate</h1>
          <p style={{textDecoration: 'none', color: 'inherit'}}><FaMapPin className='icon-contact' color="#01703d"/>Baldomero Tellez 26</p>
          <p style={{textDecoration: 'none', color: 'inherit'}}><FaPhone className='icon-contact' color="#01703d"/>(02954) 429-995</p>
          <p style={{textDecoration: 'none', color: 'inherit'}}><MdOutlineMail className='icon-contact' color="#01703d"/> repuestoselmate@gmail.com</p>
        </div>
      </div>  
  );
};

export default Home;
