import React from 'react';
import './Footer.css'
import logo from '../../images/logo.jpg'
import { FaInstagram } from "react-icons/fa";
import logoMeLi from '../../images/Logo MeLi.svg'
import { FaFacebookSquare } from "react-icons/fa";

const Footer = () => {
  return (
    <footer>
      <div className="footer-content">
        <div className="logo">
        <img src={logo} alt="Logo" />
        </div>
        <div className='socials-footer'>
        <h3>Redes Sociales</h3>
          <ul>
            <li><a href="https://www.instagram.com/"><FaInstagram color='white' fontSize={25}/></a></li>
            <li><a href="https://www.facebook.com/"><FaFacebookSquare color='white' fontSize={25}/></a></li>
            <li><a href="https://www.mercadolibre.com/"><img src={logoMeLi} height={30}alt="MercadoLibre" /></a></li>
          </ul>
        </div>
        <div className="sucursales">
  <h3>Sucursal</h3>
  <div className="sucursal">
    <div>
      <h5><strong>Santa Rosa, LP</strong></h5>
      <p>Baldomero Tellez 26</p>
      <p>2954 614 827</p>
      <p><a href="mailto:sr@franquiciasarbo.com" style={{textDecoration: 'none', color: 'inherit'}}>sr@franquiciasarbo.com</a></p>
    </div>
    {/* <div>
      <h5><strong>Mar del Plata, BA</strong></h5>
      <p>Tierra del Fuego 2141</p>
      <p>223 575 4900</p>
      <p><a href="mailto:mdp@franquiciasarbo.com">mdp@franquiciasarbo.com</a></p>
    </div>
    <div>
      <h5><strong>Tandil, BA</strong></h5>
      <p>11 3186 1835</p>
      <p><a href="mailto:tandil@franquiciasarbo.com">tandil@franquiciasarbo.com</a></p>
    </div>
    <div>
      <h5><strong>Gral. Pico, LP</strong></h5>
      <p>Calle 33 nº 1280</p>
      <p>2302 464 921</p>
      <p><a href="mailto:pico@franquiciasarbo.com">pico@franquiciasarbo.com</a></p>
    </div>
    <div>
      <h5><strong>San Bernardo</strong></h5>
      <p>Tucumán 2664</p>
      <p>02257 46-1749</p>
    </div> */}
  </div>
  </div>


      </div>
     
    </footer>
  );
}

export default Footer;