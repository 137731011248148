import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../images/logo.jpg'

import './NavBar.css'
const Navbar = () => {
  return (
<nav>
      <div className="logo">
      <img src={logo} alt="Logo" />
      </div>
      <ul className="nav-links">
        <li><Link to="/">Inicio</Link></li>
        <li><Link to="/acerca">Sobre Nosotros</Link></li>
        {/* <li><Link to="/brands">Nuestras Marcas</Link></li> */}
        <li><Link to="/contacto">Contáctanos</Link></li>
      </ul>
    </nav>
  );
}

export default Navbar;
