import logo from '../images/logo.jpg'
import './About.css'
const About = () => {
    return (
              <div className="about-page">
        <div className="about-text">
          <h2 className='title-about'>Acerca de Nosotros</h2>
          <p>
          El 20 de Julio de 1998 nace en la ciudad de Santa Rosa - La Pampa REPUESTOS EL MATE SRL. Una empresa Mayorista/Minorista de autopartes dedicada a satisfacer las necesidades de sus clientes basándose fuertemente en el servicio personalizado, la calidad y disponibilidad de sus productos en continua innovación.

La empresa cuenta con un amplio stock y excelente relación precio-calidad y sus principales rubros son SUSPENSIÓN, FRENO, LUBRICANTES, ELECTRICIDAD Y ACCESORIOS.          </p>
          {/* Agrega más texto según sea necesario */}
        </div>
        <div className="about-logo">
        <img src={logo} alt="Logo" />
        </div>
      </div>
     
    );
  }
  
  export default About;